<template>
  <scroller
    :on-refresh="refresh"
    :on-infinite="loadMore"
  >
    <h1 class="text-primary mb-2 pb-50 mt-2">
      {{ $t('pages.history.name') }}
    </h1>

    <div class="mt-2 mb-2">
      <template v-if="error">
        <div
          class="mb-5 text-muted text-center d-flex flex-column px-3 animate__animated animate__fadeIn animate__fast"
        >
          <h1 class="mt-3 mb-2">
            <i class="fa-regular fa-cloud-exclamation" />
          </h1>
          {{ $t('pages.history.list.loading-error') }}

          <div>
            <TouchButton
              size="xs"
              class="mt-2"
              @action="refresh(() => {}, true)"
            >
              {{ $t('therms.retry') }}
            </TouchButton>
          </div>
        </div>
      </template>

      <template v-else>
        <template v-if="!isFetch">
          <div class="d-flex align-items-center justify-content-center my-5 py-2">
            <b-spinner
              variant="primary"
              style="width: 3rem; height: 3rem;"
            />
          </div>
        </template>
        <template v-else>
          <template
            v-if="has"
          >
            <history-month-list
              v-for="(days, month) in list"
              :key="month"
              class="animate__animated animate__fadeIn"
              :with-year="list.length>12"
              :items="days"
            />
          </template>
          <div
            v-else
            class="mb-5 text-muted text-center d-flex flex-column px-3 animate__animated animate__fadeIn"
          >
            <h1 class="mt-3 mb-2">
              <i class="fa-solid fa-empty-set text-secondary" />
            </h1>
            {{ $t('pages.history.list.no-data') }}
          </div>
        </template>
      </template>
    </div>
  </scroller>
</template>

<script>
import HistoryMonthList from '@/views/pages/Histories/components/HistoryMonthList.vue'
import { BSpinner } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import TouchButton from '@core/components/touch-button/TouchButton.vue'

export default {
  components: {
    HistoryMonthList,
    BSpinner,
    TouchButton,
  },
  data() {
    return {
      // ui
      error: false,
    }
  },
  computed: {
    ...mapGetters({
      list: 'shipmentsHistory/getListOrderByMonth',
      isFetch: 'shipmentsHistory/isFetch',
      has: 'shipmentsHistory/has',
      count: 'shipmentsHistory/count',
    }),

  },
  async mounted() {
    this.error = false

    this.emitter.on('appStateChange', async isActive => {
      if (this.needFetch(isActive)) await this.fetch(true)
    })

    try {
      if (this.needFetch(true)) await this.fetch(true)
    } catch (err) {
      this.error = true
      throw err
    }
  },
  beforeDestroy() {
    this.emitter.off('appStateChange')
  },
  methods: {
    /**
     * @param {Function} finishToRefresh
     * @param {Boolean} force
     * @returns {Promise<void>}
     */
    async refresh(finishToRefresh, force = false) {
      this.error = false

      try {
        if (force) this.$store.commit('shipmentsHistory/clear')
        await this.$store.dispatch('shipmentsHistory/fetch', true)
        finishToRefresh()
      } catch (err) {
        this.error = true
        finishToRefresh()

        throw err
      }
    },
    /**
     * @param {Function} finishToLoadMore
     * @returns {Promise<void>}
     */
    async loadMore(finishToLoadMore) {
      // hides update status if no items are present
      if (this.error || !this.has || this.count < 10) {
        finishToLoadMore(false)
        return
      }

      try {
        const r = await this.$store.dispatch('shipmentsHistory/fetch')
        finishToLoadMore(r)
      } catch (err) {
        this.error = true
        finishToLoadMore(false)

        throw err
      }
    },
    /**
     * @param {Boolean} isActive
     * @returns {boolean}
     */
    needFetch(isActive = false) {
      if (this.$store.getters['shipmentsHistory/getLastPull'] === null) return true

      return (isActive && this.$store.getters['shipmentsHistory/getLastPull']
        .locale('en')
        .format('YYYY-MM-DD') !== this.$moment()
        .locale('en')
        .format('YYYY-MM-DD'))
    },
    /**
     * @param {Boolean} force
     */
    async fetch(force = false) {
      if (force) {
        this.$store.commit('shipmentsHistory/clear')
      }

      if (!this.$store.getters['shipmentsHistory/isFetch']) {
        await this.$store.dispatch('shipmentsHistory/fetch', true)
      }
    },
    /**
     * @param {Number|String} v
     * @returns {*}
     */
    numberFormat(v) {
      return window.numberFormat(v)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../@core/scss/base/bootstrap-extended/_include';
@import '../../../@core/scss/base/components/_include';

table tr td:nth-child(2) {
  padding-left: 20px;
}

.inputDate {
  position: relative;
  z-index: -100;
  top: 10px;
  opacity: 0;
}

</style>
