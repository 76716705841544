<template>
  <span
    v-touch:start="onStart"
    v-touch:tap="onTap"
    v-touch:longtap="onLongTap"
    v-touch:moving="onEnd"
    v-touch:end="onEnd"
    class=""
    :class="[{'hold': holdOn}, color, size]"
    :disabled="disabled"
  >
    <slot />
  </span>
</template>

<script>

export default {
  components: {
  },
  props: {
    size: {
      type: String,
      default: 'md',
    },
    color: {
      type: String,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      holdOn: false,
    }
  },
  methods: {
    onStart(e) {
      if (this.disable || this.disabled) return
      this.holdOn = true
      this.$emit('actionStart', e)
    },
    async onTap(e) {
      if (this.disable || this.disabled) return

      this.holdOn = true
      this.$emit('action', e)
      await window.sleep(200)
      this.holdOn = false
    },
    async onLongTap(e) {
      if (this.disable || this.disabled) return

      this.holdOn = true
      this.$emit('actionLongTap', e)
      await window.sleep(200)
      this.holdOn = false
    },
    onEnd(e) {
      this.$nextTick(async () => {
        await window.sleep(200)
        this.holdOn = false
        this.$emit('actionEnd', e)
      })
    },
  },
}
</script>

<style scoped lang="scss">
@use "sass:map";
@import '@core/scss/base/bootstrap-extended/_include';
@import '@core/scss/base/components/_include';

span {
  display: flex;
  box-shadow: 0 2px 6px rgb(0 0 0 / 15%);
  background-color: #ffffff;
  color: $gray-800;
  text-align: center;
  border: none;
  border-radius: 1000px;
  align-items: center;
  justify-content: center;

  &.sm {
    height: 16px;
    width: 16px;
    font-size: 0.5rem;
  }

  &.md {
    height: 22px;
    width: 22px;
    font-size: 0.65rem;
  }

  &.lg {
    height: 30px;
    width: 30px;
    font-size: 0.8rem;
  }

  &.xl {
    height: 45px;
    width: 45px;
    font-size: 1.1rem;
  }

  @each $name, $value in $theme-colors {
    &.#{$name} {
      background-color: $value;
      color: map.get($text-color-with-background-color, $name);

      &.hold {
        background-color: darken($value, 10);
      }

      &.disabled {
        opacity: 0.6;
      }
    }
  }
}

</style>
