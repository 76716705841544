<template>
  <button
    v-touch:start="onStart"
    v-touch:tap="onTap"
    v-touch:longtap="onLongTap"
    v-touch:moving="onEnd"
    v-touch:end="onEnd"
    :disabled="disabled"
    class="d-inline-flex align-items-center justify-content-center"
    :class="[{'hold': holdOn, 'in-group':ingroup, 'outline':outline, 'active' : active, 'thinkiness': thinkiness}, color, size]"
  >
    <slot />
  </button>
</template>

<script>
export default {
  components: {},
  props: {
    size: {
      type: String,
      default: 'md',
    },
    color: {
      type: String,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    ingroup: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    thinkiness: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      holdOn: false,
    }
  },
  methods: {
    onStart(e) {
      if (this.disabled || this.disable) return
      this.holdOn = true
      this.$emit('actionStart', e)
    },
    async onTap(e) {
      if (this.disabled || this.disable) return

      this.holdOn = true
      this.$emit('action', e)
      await window.sleep(200)
      this.holdOn = false
    },
    async onLongTap(e) {
      if (this.disabled || this.disable) return

      this.holdOn = true
      this.$emit('actionLongTap', e)
      await window.sleep(200)
      this.holdOn = false
    },
    onEnd(e) {
      this.$nextTick(async () => {
        await window.sleep(200)
        this.holdOn = false
        this.$emit('actionEnd', e)
      })
    },
  },
}
</script>

<style scoped lang="scss">
@use "sass:map";
@import '@core/scss/base/bootstrap-extended/_include';
@import '@core/scss/base/components/_include';

button {
  box-shadow: 0 3px 13px rgb(0 0 0 / 5%);
  background-color: #ffffff;
  color: $gray-800;
  text-align: center;
  border: none;
  border-radius: 0.6rem;

  &.in-group {
    border-radius: initial !important;

    span {
      width: 100%;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &.wrap {
        white-space: pre-wrap;
      }
    }
  }

  &.xs {
    font-size:0.8rem;
    padding: 7px 12px;

    &.thinkiness {
      padding: 6px 20px;
    }
  }

  &.sm {
    font-size: 1rem;
    padding: 10px 20px;

    &.thinkiness {
      padding: 6px 20px;
    }
  }

  &.md {
    font-size: 1.2rem;
    padding: 10px 20px;

    &.thinkiness {
      padding: 8px 20px;
    }
  }

  &.lg {
    font-size: 1.3rem;
    padding: 15px 25px;

    &.thinkiness {
      padding: 10px 20px;
    }
  }

  &.xl {
    font-size: 1.6rem;
    padding: 15px 30px;

    &.thinkiness {
      padding: 10px 20px;
    }
  }

  @each $name, $value in $theme-colors {
    &.#{$name} {
      background-color: $value;
      color: map.get($text-color-with-background-color, $name);

      &.outline {
        background-color: transparent !important;
        border: 1px solid $value;
        color: $value !important;

        &.in-group {
          border: 0;
          border-right: 1px solid $value;
        }

        &.active {
          background-color: transparentize($value, 0.78) !important;
        }
      }

      &.hold {
        background-color: darken($value, 10) !important;

        &.outline:not(.active) {
          background-color: transparentize($value, 0.28) !important;
          color: white !important;
        }
      }

      &:disabled, &[disabled] {
        box-shadow: none;
        opacity: 0.6;
      }
    }
  }
}
</style>
