<template>
  <div>
    <h6 class="mt-2 pt-50 mb-1">
      {{ monthFormat.charAt(0).toUpperCase() + monthFormat.slice(1) }}
    </h6>
    <b-list-group>
      <history-day-item
        v-for="item in itemsSorted"
        :key="item.date"
        :item="item"
      />
    </b-list-group>
  </div>
</template>

<script>
import { BListGroup } from 'bootstrap-vue'
import HistoryDayItem from '@/views/pages/Histories/components/HistoryDayItem.vue'

export default {
  components: {
    BListGroup,
    HistoryDayItem,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    withYear: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    monthFormat() {
      if (this.items.length === 0) return null
      if (this.withYear) return this.$moment(this.items[0].date).format('MMMM YYYY')
      return this.$moment(this.items[0].date).format('MMMM')
    },
    itemsSorted() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.items
        .sort((a, b) => new Date(b.date) - new Date(a.date))
    },
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
@import '../../../../@core/scss/base/bootstrap-extended/_include';
@import '../../../../@core/scss/base/components/_include';

table tr td:nth-child(2) {
  padding-left: 20px;
}

.inputDate {
  position: relative;
  z-index: -100;
  top: 10px;
  opacity: 0;
}

</style>
