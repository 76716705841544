<template>
  <b-list-group-item
    class="d-flex justify-content-between align-items-center"
    @action="goTo(item)"
  >
    {{ dayFormat }}
    <b-list-group-item-button
      disable
      color="primary"
    >
      <i class="fa-solid fa-angle-right" />
    </b-list-group-item-button>
  </b-list-group-item>
</template>

<script>
import ListGroupItem from '@core/components/list-group/ListGroupItem.vue'
import ListGroupItemButton from '@core/components/list-group/ListGroupItemButton.vue'
import { NAME_HISTORY_DATE } from '@/router/routes/history'

export default {
  components: {
    'b-list-group-item': ListGroupItem,
    'b-list-group-item-button': ListGroupItemButton,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    dayFormat() {
      return this.$moment(this.item.date).format('DD/MM/YYYY')
    },
  },
  methods: {
    goTo(item) {
      this.$router.push({
        name: NAME_HISTORY_DATE,
        params: {
          date: item.date,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../@core/scss/base/bootstrap-extended/_include';
@import '../../../../@core/scss/base/components/_include';

table tr td:nth-child(2) {
  padding-left: 20px;
}

.inputDate {
  position: relative;
  z-index: -100;
  top: 10px;
  opacity: 0;
}

</style>
