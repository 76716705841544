<template>
  <b-list-group-item
    v-touch:start="onStart"
    v-touch:tap="onTap"
    v-touch:longtap="onLongTap"
    v-touch:moving="onEnd"
    v-touch:end="onEnd"
    :class="[{'hold': holdOn}]"
    :disabled="disabled"
  >
    <slot />
  </b-list-group-item>
</template>

<script>
import { BListGroupItem } from 'bootstrap-vue'

export default {
  components: {
    BListGroupItem,
  },
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      holdOn: false,
    }
  },
  methods: {
    onStart(e) {
      if (this.disable || this.disabled || !this.active) return
      this.holdOn = true
      this.$emit('actionStart', e)
    },
    async onTap(e) {
      if (this.disable || this.disabled || !this.active) return

      this.holdOn = true
      this.$emit('action', e)
      await window.sleep(200)
      this.holdOn = false
    },
    async onLongTap(e) {
      if (this.disable || this.disabled || !this.active) return

      this.holdOn = true
      this.$emit('actionLongTap', e)
      await window.sleep(200)
      this.holdOn = false
    },
    onEnd(e) {
      this.$nextTick(async () => {
        await window.sleep(200)
        this.holdOn = false
        this.$emit('actionEnd', e)
      })
    },
  },
}
</script>

<style scoped lang="scss">
@use "sass:map";
@import '@core/scss/base/bootstrap-extended/_include';
@import '@core/scss/base/components/_include';

.hold {
  background-color: darken($white, 10);
}

.list-group-item {
  border-left: 0;
  border-right: 0;
  border-top: 0;
  padding: 0.95rem 1.25rem;
  display: flex;

  &:last-child {
    border-bottom: 0;
  }
}

</style>
